* {
  box-sizing: border-box;
}

body {
  background-color: #1c4b64;
}

html {
  font-family: "Cabin";
  font: 1em/1.5 "Cabin", Arial, Helvetica, sans-serif;
  min-height: 100%;
  font-size: 1.5rem;
}

.page {
  background-color: #1c4b64;
}

.page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.navbar {
  -webkit-box-shadow: 0 2px 10px -2px #999;
  -moz-box-shadow: 0 2px 10px -2px #999;
  box-shadow: 0 1px 10px -2px #999;
  background-color: #043454;
}

.navigation {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  padding: 45px;
  max-height: 28vh;
}

.navigation-main {
  float: right;
  -webkit-box-align: center;
  align-items: center;
  width: 80vw;
  margin-left: 10vw;
}

.navigation-main a {
  float: left;
  text-transform: uppercase;
  font-size: 1.6rem;
}

nav {
  display: inline-block;
}

.App-link {
  color: #61dafb;
}

.font-white {
  color: white;
}

.carousel {
  border-top: 1px solid black;
  border-bottom: 2px solid black;
}

.carousel-caption div {
  text-transform: uppercase;
  font-family: oswald, "arial narrow";
  font-size: 40px;
  width: 100%;
  padding: 5px 20px;
  overflow: hidden;
  flex-direction: row;
}

.featured-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background-color: rgba(22, 22, 22, 50);
  margin: 0;
  padding: 0;
  text-align: left;
  opacity: 0.5;
  color: #033653;
}

.featured-bottom a {
  font-size: medium;
  color: white;
  margin-top: 15px;
  margin-left: 20px;
  text-transform: uppercase;
  border: 1px solid white;
  padding: 5px;
}

.featured-bottom:hover {
  filter: grayscale(0%);
  filter: gray;
  -webkit-filter: grayscale(0%);
  filter: none;
  transition: 1s ease;
}

.flex-grid {
  display: flex;
  justify-content: space-between;
}

.flex-grid .right {
  line-height: 1;
}

.featured-date {
  text-decoration: underline;
  flex-direction: column;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0px 1px 1px #e6dac2;
}

.featured-note {
  display: inline-block;
  font-size: medium;
  font-weight: 300;
  padding-top: 0;
  margin-top: 0px;
  line-height: 1;
  text-shadow: none;
}

.featured-heading {
  flex-direction: column;
  line-height: 1;
  font-weight: 700;
  text-rendering: auto;
  text-align: right;
  opacity: 110;
  padding-top: 15px;
  text-shadow: 0px 1px 1px #e6dac2;
}

time.calendar {
  font-size: 0.5em; /* change icon size */
  display: block;
  position: relative;
  width: 7em;
  height: 8em;
  background-color: #fff;
  border-radius: 0.6em;
  box-shadow: 0 1px 0 #bdbdbd;
  overflow: hidden;
}

time.calendar * {
  display: block;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  text-align: center;
}

time.calendar strong {
  position: absolute;
  top: 0;
  padding: 0;
  color: #fff;
  background-color: #987c45;
  border-bottom: 1px dashed #987c45;
  box-shadow: 0 1px 0 #987c45;
}

time.calendar em {
  position: absolute;
  bottom: 0;
  color: #987c45;
}

time.calendar span {
  font-size: 2.8em;
  letter-spacing: -0.05em;
  padding-top: 1.1em;
  color: #2f2f2f;
}

.event-listing {
  color: white;
  font-family: oswald, "arial narrow";
}

/* ELEMENTS */

h1 {
  margin: 20px 0 30px;
  letter-spacing: -2px;
  color: #e6dac2;
  text-transform: uppercase;
  font-family: "Roboto Slab", serif;
  text-align: center;
}
