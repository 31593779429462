@import 'App.scss';
//override default variables before the import
$brand-primary:         darken(#428bca, 6.5%) !default; // #337ab7
$body-bg: #1c4b64 !default;
$link-color: #ffffff;
$font-family-sans-serif: "Cabin", Helvetica, Arial, sans-serif;

@media screen and (min-width: 576px){
    body{
        text-align: left;
        display: block;
    }
    .event-item {
        text-align: left;
    }
    .event-image {
        margin: 0;
    }
}
//import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.carousel-caption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    background-color: rgba(22, 22, 22, 50);
    margin: 0;
    padding: 0;
    text-align: left;
    opacity: 0.5;
    color: #033653;
  }

  html {
    font-size: 1rem;
  }
  
  @include media-breakpoint-up(sm) {
    html {
      font-size: 1.2rem;
    }
  }
  
  @include media-breakpoint-up(md) {
    html {
      font-size: 1.4rem;
    }
  }
  
  @include media-breakpoint-up(lg) {
    html {
      font-size: 1.6rem;
    }
  }


